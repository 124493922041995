import "./App.css";
import { BrowserRouter, useHistory } from "react-router-dom";
import { Navigator } from "./Navigator";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  HttpLink,
  useReactiveVar,
} from "@apollo/client";
import NotificationAlert from "react-notification-alert";
import React from "react";
import { errorMsg, isTableDataLoading, successMsg } from "./cache/vars";
import { onError } from "@apollo/client/link/error";

function App() {
  const history = useHistory();
  const notificationAlertRef = React.useRef(null) as any;

  const errorMessage = useReactiveVar(errorMsg);
  const successMessage = useReactiveVar(successMsg);

  const authLink = new ApolloLink((operation, forward) => {
    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0].replace("jwt=", "");

    operation.setContext({
      headers: {
        authorization: jwtValue ? `Bearer ${jwtValue}` : "",
      },
    });

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const extensionsCode = extensions && extensions.code ? extensions.code : null;
        switch (extensionsCode) {
          case "UNAUTHENTICATED":
            const jwt = document.cookie.match(/jwt=[^\s]+/);
            const jwtValue = jwt && jwt[0].replace("jwt=", "");
            errorMsg(message);
            if (jwtValue) {
              history.push("/login");
              break;
            }
            break;

          case "BAD_USER_INPUT":
            isTableDataLoading(false);
            return errorMsg(message || "The field(s) cannot be empty");

          case "INTERNAL_SERVER_ERROR":
          case "500":
            isTableDataLoading(false);
            return errorMsg(message);
          default:
            console.warn(`[Unhandled error code]: ${extensionsCode}`);
            return errorMsg(message);
        }
      });
    }
  
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({
    uri: `${
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development" ? "http" : "https"
    }://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`,
    credentials: "include",
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const notify = (type: any, errorMessage: string) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span data-notify="message">{errorMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  React.useEffect(() => {
    if (errorMessage) {
      notify("warning", errorMessage);
      errorMsg("");
    }
    if (successMessage) {
      notify("success", successMessage);
      successMsg("");
    }
  }, [errorMessage, successMessage]);

  return (
    <ApolloProvider client={client}>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Navigator />
    </ApolloProvider>
  );
}

const Root = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default Root;
